import { useAuthUserOrNull } from "@frontegg/react";
import { User } from "@frontegg/redux-store";
import {
  Box,
  BoxProps,
  Drawer,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { PlatformRole } from "@noogata/types";

import { useHistory } from "react-router-dom";
import AppModeButton from "../../../components/AppModeButton.tsx";
import AppModeClosedButton from "../../../components/AppModeClosedButton.tsx";
import { getUserMostPrivilegeRole } from "../../../services/auth.ts";
import { DigitalShelfList } from "./DigitalShelfList.tsx";
import { InsightsNavItem } from "./InsightsNavItem.tsx";
import { LogoutNavItem } from "./LogoutNavItem.tsx";
import { PerfectContentList } from "./PerfectContentList.tsx";
import { TrendspottingList } from "./TrendspottingList.tsx";
import { CustomDashboardsList } from "./CustomDashboardsList.tsx";
import { NavbarModes } from "../../../enums.ts";
import { useAppContext } from "../../../ApplicationContext.tsx";
import { AdBoosterList } from "./AdBoosterList.tsx";
import { Split } from "../../../FeatureFlags/enums.ts";
import { useSplitEnabled } from "../../../hooks/index.ts";
import {
  ThemeProviderV2,
  useIsDarkMode,
} from "../../../components/ThemeProviderV2.tsx";
import { SalesInsightsNavItem } from "./SalesInsightsNavItem.tsx";
import { Tracking } from "./Tracking.tsx";
import { FC, useEffect } from "react";
import { Tasks } from "./Tasks.tsx";
import { InsightsChatNavItem } from "./InsightsChatNavItem.tsx";
// import { SpacesNavItem } from "./SpacesNavItem.tsx";
import { InsightsChatV2NavItem } from "./InsightsChatV2NavItem.tsx";
import { SpacesV2NavItem } from "./SpacesV2NavItem.tsx";
import { TenantSelectionV2 } from "../../../components/TenantSelectionV2.tsx";
import { UserSectionV2 } from "../../../components/LabLeftNav/UserSectionV2.tsx";
// import { PinIcon, PinnedIcon } from "../../../assets/index.tsx";
import { CatalogEnrichmentNavItem } from "./CatalogEnrichmentNavItem.tsx";
import NavbarIndicator from "../../../components/NavbarIndicator.tsx";

interface ApplicationsLeftNavProps {
  isDrawerPinned: boolean;
  setIsDrawerPinned: (isDrawerPinned: boolean) => void;
}

const Logo: FC<{ size?: "full" | "favicon" } & Partial<BoxProps>> = ({
  size = "full",
  ...props
}) => {
  const history = useHistory();
  const isDarkMode = useIsDarkMode();
  return (
    <Box onClick={() => history.push("/")} {...props}>
      {size === "full" ? (
        <img
          alt=""
          src={`/${isDarkMode ? "logo-dark-mode.svg" : "logo-black.svg"}`}
          height={30}
          width={114}
          // style={{
          //   marginLeft: 2,
          // }}
        />
      ) : (
        <img
          alt=""
          src={`/favicon-dark.svg`}
          height={30}
          width={30}
          style={{ marginLeft: "12px" }}
        />
      )}
    </Box>
  );
};

const ApplicationsLeftNav: FC<ApplicationsLeftNavProps> = ({
  // isDrawerPinned,
  setIsDrawerPinned,
}) => {
  const theme = useTheme();
  const { navbarMode, setNavbarMode } = useAppContext();
  const insightsEnabled = useSplitEnabled(Split.INSIGHTS);
  const salesAssistantEnabled = useSplitEnabled(Split.SALES_ASSISTANT);
  const open = navbarMode === NavbarModes.EXPENDED;
  // const [open, setOpen] = useState(navbarMode === NavbarModes.EXPENDED)
  const isAdBoosterEnabled = useSplitEnabled(Split.AD_BOOSTER);
  const isTaskEnabled = useSplitEnabled(Split.SALES_ASSISTANT_TASKS);
  const isSpacesV2Enabled = useSplitEnabled(Split.SPACES_V2);
  const isCatalogEnrichmentEnabled = useSplitEnabled(Split.CATALOG_ENRICHMENT);
  const isChatV2Enabled = useSplitEnabled(Split.CHAT_V2_FF);
  const isTracking = useSplitEnabled(Split.TRACKING);
  const insightsChatEnabled = useSplitEnabled(Split.INSIGHTS_CHAT);
  const isDrawerPinnedFromLocal = localStorage.getItem("isDrawerPinned");

  useEffect(() => {
    if (isDrawerPinnedFromLocal) {
      if (isDrawerPinnedFromLocal === "true") {
        setIsDrawerPinned(true);
        setNavbarMode(NavbarModes.EXPENDED);
      }
    }
  }, [isDrawerPinnedFromLocal]);

  const width = open ? theme.spacing(30) : `calc(${theme.spacing(9)} + 1px)`;

  const container =
    typeof window !== "undefined" ? window.document.body : undefined;
  const user: User | null = useAuthUserOrNull();
  const role: string = user
    ? getUserMostPrivilegeRole(user).key
    : PlatformRole.ApplicationUser;

  // const handleOpenNavbar = () => {
  //   if (isDrawerPinned) {
  //     return;
  //   }
  //   setNavbarMode(NavbarModes.EXPENDED);
  // };

  // const handleCloseNavbar = () => {
  //   if (isDrawerPinned) {
  //     return;
  //   }
  //   setNavbarMode(NavbarModes.COLLAPSED);
  // };

  // const handlePinDrawer = () => {
  //   setIsDrawerPinned(!isDrawerPinned);
  //   localStorage.setItem("isDrawerPinned", JSON.stringify(!isDrawerPinned));
  // };

  return (
    <Drawer
      open
      container={container}
      variant="permanent"
      sx={{
        width,
        display: { xs: "block", sm: "block" },
        // position: !isDrawerPinned ? "absolute" : "",
        "& .MuiDrawer-paper": {
          overflowX: "hidden",
          overflowY: "auto",
          px: open ? 2 : 1,
          pb: 2,
          transition: "width 0.5s ease",
          boxSizing: "border-box",
          width,
          background: theme.palette.background.default,
        },
      }}
      // onMouseEnter={handleOpenNavbar}
      // onMouseLeave={handleCloseNavbar}
    >
      <Stack flex={1} justifyContent="space-between">
        <Stack spacing={1}>
          <Stack
            // p={1}
            mb={4}
            // paddingLeft={1}
            paddingTop={2}
            sx={{
              marginBottom: "1rem !important",
              paddingBottom: "10px",
              position: "sticky",
              top: 0,
              left: 0,
              zIndex: "10000",
              background: "#2C224C",
            }}
          >
            <Logo size={open ? "full" : "favicon"} />
            {/* {open && (
              <Box
                sx={{
                  position: "absolute",
                  display: "inline-flex",
                  top: "15px",
                  right: "0",
                  zIndex: "10000",
                  cursor: "pointer",
                }}
                onClick={handlePinDrawer}
              >
                {isDrawerPinned ? <PinnedIcon /> : <PinIcon />}
              </Box>
            )} */}
          </Stack>
          <Stack
            spacing={2}
            sx={{
              borderBottom: "2px solid rgb(209 211 212 / 20%)",
              pb: 3,
              mb: "1rem !important",
            }}
          >
            {open && (
              <Typography color="#FFFFFF" fontWeight={500} fontSize="12px">
                SALES ASSISTANT
              </Typography>
            )}
            <Stack spacing={open ? 1 : 2}>
              {salesAssistantEnabled && <SalesInsightsNavItem />}
              {insightsEnabled && <InsightsNavItem />}
              <PerfectContentList open={open} />
            </Stack>
          </Stack>
          <Stack
            spacing={2}
            sx={{
              borderBottom: "2px solid rgb(209 211 212 / 20%)",
              pb: 3,
              mb: "1rem !important",
            }}
          >
            {open && (
              <Typography color="#FFFFFF" fontWeight={500} fontSize="12px">
                RESEARCH ASSISTANT
              </Typography>
            )}
            <Stack spacing={open ? 1 : 2}>
              {insightsChatEnabled && <InsightsChatNavItem />}
              {isChatV2Enabled && <InsightsChatV2NavItem />}
              <DigitalShelfList open={open} />
            </Stack>
          </Stack>
          <Stack spacing={open ? 1 : 2}>
            {/* {salesAssistantEnabled && <SalesInsightsNavItem />} */}
            {/* {insightsChatEnabled && <InsightsChatNavItem />}
            {isChatV2Enabled && <InsightsChatV2NavItem />} */}
            {isSpacesV2Enabled && <SpacesV2NavItem />}

            {/* {isCatalogEnrichmentEnabled &&  */}
            {isCatalogEnrichmentEnabled && <CatalogEnrichmentNavItem />}
            {/* } */}

            {isTaskEnabled && <Tasks />}
            {/* {insightsEnabled && <InsightsNavItem />} */}
            {/* <DigitalShelfList open={open} /> */}
            {/* <PerfectContentList open={open} /> */}
            {isAdBoosterEnabled && <AdBoosterList open={open} />}
            <CustomDashboardsList open={open} />
            <TrendspottingList open={open} />
            {isTracking && <Tracking />}
          </Stack>
        </Stack>
        <Stack spacing={1}>
          <LogoutNavItem />
          {role !== PlatformRole.ApplicationUser &&
            (open ? <AppModeButton /> : <AppModeClosedButton />)}
          {open && <TenantSelectionV2 />}
          <UserSectionV2
            open={open}
            sx={{
              marginLeft: theme.spacing(1),
              maxHeight: theme.spacing(40),
            }}
          />
          <NavbarIndicator />
        </Stack>
      </Stack>
    </Drawer>
  );
};

export default ({
  isDrawerPinned,
  setIsDrawerPinned,
}: ApplicationsLeftNavProps) => (
  <ThemeProviderV2>
    <ApplicationsLeftNav
      isDrawerPinned={isDrawerPinned}
      setIsDrawerPinned={setIsDrawerPinned}
    />
  </ThemeProviderV2>
);
