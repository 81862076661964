import { FC } from "react";

import {
  Avatar,
  CardHeader,
  CardHeaderProps,
  Stack,
  Typography,
} from "@mui/material";
import { useAuthUserOrNull } from "@frontegg/react-hooks/auth";
import { useStyles } from "./useStyles";
import theme from "../../themes/light";

export const UserSectionV2: FC<{ open?: boolean } & CardHeaderProps> = ({
  open,
  ...props
}) => {
  const user = useAuthUserOrNull();
  const classes = useStyles();

  return open ? (
    <CardHeader
      className={classes.userSection}
      sx={{
        flexWrap: "nowrap",
        p: 0.875,
        // "& .MuiAvatar-root .MuiAvatar-circular": {
        //   height: "48px",
        //   width: "48px"
        // }
      }}
      avatar={
        <Avatar
          src={user?.profilePictureUrl || ""}
          sx={{ height: "48px", width: "48px" }}
        />
      }
      title={
        <Typography
          sx={{
            color: theme.palette.backgrounds.white,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "138px",
            fontSize: "15px",
            fontWeight: 600,
            overflow: "hidden",
          }}
        >
          {open ? user?.name : ""}
        </Typography>
      }
      subheader={
        <Typography
          sx={{
            color: theme.palette.backgrounds.white,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "130px",
            fontSize: "13px",
            fontWeight: 500,
            overflow: "hidden",
          }}
        >
          {open ? (user?.email ? user?.email : user?.name) : ""}
        </Typography>
      }
      {...props}
    />
  ) : (
    <Stack alignItems="center">
      <Avatar
        src={user?.profilePictureUrl || ""}
        sx={{ height: "48px", width: "48px" }}
      />
    </Stack>
  );
};
